import {
    Button,
    Card,
    CardContent,
    Typography,
  } from "@mui/material";
  import SendToMobileIcon from '@mui/icons-material/SendToMobile';

  interface IPhoneLoginProps {}
  
  const PhoneLogin: React.FC<IPhoneLoginProps> = (props) => {
  
    return (
      <>
        <Card style={{ width: 400 }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Phone Login
            </Typography>
            Your password has been reset. Please log in through the app.
          <br /><br />
          <div onClick={() => window.open("acensahealth://open", "_blank")} style={{cursor: 'pointer', width: '100%', textAlign: 'center'}}>
          <SendToMobileIcon style={{fontSize: 32}}/><br />
          <Button variant={'outlined'}>Launch App</Button>
            </div>
          </CardContent>
        </Card>
      </>
    );
  };
  
  export default PhoneLogin;
  