import { normalize, schema } from 'normalizr';
import { all, call, put, take } from 'redux-saga/effects';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { ICreateUploadResourceResponse, IResourceFileUploadPayload } from '../../shared/interfaces/model/resource.interfaces';
import { CompletedPart } from '../../shared/interfaces/requests/aws.interface';
import { dateFormatted } from '../../shared/utils/date-utils';
import { getFileExtension, getFileParts, getFileType } from '../../utils/file';
import GetFriendlyError from '../../utils/general';
import { initPresignedUrlMultipartUpload, completeMultipartUpload, initPresignedUrlSimpleUpload } from '../actions/awsActions';
import { createUploadResource } from '../actions/resourceActions';
import { dashboardDataSchema, userSchema, userNotesSchema, roleSchema, userRoleSchema } from './schema';
const client = new HttpClient();

async function createUploadResourcesAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.post('api/resource/create-resource', data);
}

const resourceSaga = {

  * createUploadResource(action: IDispatchAction): Generator {
  try {
	  const payload = action.payload as any;
	  console.log("createUploadResourcesAPI payload", payload);
	  const response = (yield call(createUploadResourcesAPI, payload)) as HttpResponse<any>;
	 
	  console.log("create-upload-resource-response", response);
	  if (response.status === 200 || response.status === 201) {
		  //TODO, this should return and add or remove user_roles
		  console.log("putting the success");
		  yield put(
			{
				type: ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS,
				payload: response.data
			}
		  );
		  if (action.onSuccess) {
			  action.onSuccess(response.data);
		  }
	  }
  } catch (e) {
	  if (action.onFail) {
		  action.onFail();
	  }
	  const error = e as Error;
	  const friendlyError = GetFriendlyError(error.message);
	  yield put({ type: ACTIONS.ERROR, message: friendlyError });
  }
},


*uploadResourceFile(action: IDispatchAction<IResourceFileUploadPayload>): Generator {
    if (!action.payload) {
      throw new Error('Payload cannot be empty');
    }

    const parts = getFileParts(action.payload.file);
	const extension =  getFileExtension(action.payload.file.name)
    //Creating resource.
	console.log("uploadResourceFile before put", action.payload)
    yield put(
      createUploadResource({
        name: action.payload.name,
        model: action.payload.model,
        model_id: action.payload.model_id,
        member_id: action.payload.member_id,
        extension,
        type: getFileType(action.payload.file.name),
        parts
      })
    );
	console.log("uploadResourceFile after put")
    const responseAction = (yield take(
      ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS
    )) as IDispatchAction<ICreateUploadResourceResponse>;
    const urls = responseAction.payload?.urls;
		console.log("got the urls?", urls);
    // We can upload the file now.
    if (parts > 1) {
      yield put(
        initPresignedUrlMultipartUpload(
          { file: action.payload.file, urls: urls as string[] },
          action.onSuccess,
          action.onFail,
          action.onProgress
        )
      );
      const multipartUploadAction = (yield take(
        ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS
      )) as IDispatchAction;

      // We complete the multipart upload here.
      const completeMultipartUploadPayload = multipartUploadAction.payload as CompletedPart[];
      const key = 'test/' + action.payload.file.name + '.' + extension // `${getResourceFullPath(responseAction.payload?.resource as IResource)}`;
      yield put(
        completeMultipartUpload({
          key,
          uploadId: responseAction.payload?.uploadId ?? '',
          parts: completeMultipartUploadPayload
        })
      );
    } else {
		console.log("doing single upload", action.payload.file)
		console.log("use the url", urls![0]);
      yield put(
        initPresignedUrlSimpleUpload(
          { file: action.payload.file, url: urls![0] as string },
          action.onSuccess,
          action.onFail,
          action.onProgress
        )
      );
      yield take(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS);
    }
  }



};

export default resourceSaga;
