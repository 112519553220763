import { isArray } from 'lodash'
import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'

export const roleSchema = new schema.Entity('roles')
export const userRoleSchema = new schema.Entity('user_roles')
export const userSchema = new schema.Entity('users')
export const memberSchema = new schema.Entity('members')
export const userNotesSchema = new schema.Entity('user_notes')
export const resourceSchema = new schema.Entity('resources')
export const questionSchema = new schema.Entity('questions')
export const questionSetSchema = new schema.Entity('question_sets')
export const dataCollectionSchema = new schema.Entity('data_collections')
export const dashboardDataSchema = new schema.Entity('dashboard_data', {}, { idAttribute: 'uuid' })

export function autoYields (data: any): any[]{
    const yields = [] as any[]
    const pData = {
      dashboard_data: dashboardDataSchema,
      data_collection: dataCollectionSchema,
      data_collections: [dataCollectionSchema],
      resource: resourceSchema,
      resources: [resourceSchema],
      role: roleSchema,
      roles: [roleSchema],
      user: userSchema,
      users: [userSchema],
      member: memberSchema,
      members: [memberSchema],
      user_note: userNotesSchema,
      user_notes: [userNotesSchema],
      question: questionSchema,
      questions: [questionSchema],
      question_set: questionSetSchema,
      question_sets: [questionSetSchema],
      user_role: userRoleSchema,
      user_roles: [userRoleSchema],
    } as any

    Object.keys(data).forEach((property: string) => {
      if (pData[property]) {
        yields.push(put({ type: 'REDUCE_MERGE_' + property + '_SUCCESS', normalized: normalize(data[property], pData[property])}));
      }
      else if (property.startsWith('deleted_')){ // check for deleted keys
        const compareString = property.replace('deleted_', '')
        if (pData[compareString]) {
          let deleteIds = data[property];
          let stateKey = '';
          let idAttribute = '';
          if (!isArray(deleteIds)){
            deleteIds = [deleteIds]
          }
          if (isArray(pData[compareString])){
            stateKey = pData[compareString][0]._key
            idAttribute = pData[compareString][0]._idAttribute
          }
          else{
            stateKey = pData[compareString]._key
            idAttribute = pData[compareString]._idAttribute
          }
          deleteIds = deleteIds.map((obj: any) => obj[idAttribute])
          const removeKeys = [{ entities: stateKey, ids: deleteIds }];
          yields.push(put({ type: 'REDUCE_DELETE_' + property + '_SUCCESS', removeKeys, forceStateRefresh: true}));
        }
      }
    });
    return yields
}