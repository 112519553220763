




import MaterialTable from '@material-table/core';
import { Download } from '@mui/icons-material';
import { TableRow, TableCell, TableContainer, Paper, Table, TableBody, TableHead, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { getDashboardDetailData } from '../../store/actions/adminActions';
import { tableIcons } from '../shared/MaterialTableShared';
import StandardModal from '../shared/StandardModal';

interface IDashboardDetails {
    open: boolean;
    setOpen: Function;
}

const DashboardDetails: React.FC<IDashboardDetails> = (props) => {

    const [dashboardDetailData, setDashboardDetailData] = useState<null | any>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashboardDetailData({}, (data: any) => {
            console.log("DETAIL RESPONSE", data)
            setDashboardDetailData(data.meta);
        }))
    }, []);

    const columns = [
        {
            title: 'First', field: 'first_name', sorting: true, cellStyle: {}
        },
        {
            title: 'Last', field: 'last_name', sorting: true, cellStyle: {}
        },
        {
            title: 'Email', field: 'email', sorting: true, cellStyle: {}
        },
        {
            title: 'Members', field: 'memberCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Appointments', field: 'appointmentCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Prescriptions', field: 'prescriptionCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Journal Entries', field: 'journalEntryCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Allergies', field: 'allergyCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Diagnoses', field: 'diagnosisCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Family Diagnoses', field: 'familyDiagnosisCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Surgery Hosp.', field: 'surgeryHospitalizationCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Wallet', field: 'walletCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Card', field: 'cardCount', sorting: true, cellStyle: {}
        },
        {
            title: 'Contact', field: 'contactCount', sorting: true, cellStyle: {}
        }
    ]

    return (
        <StandardModal
            open={props.open}
            onClose={() => {
                props.setOpen(false);
            }}
            maxWidth={'xlg'}
            title={'Metric Details'}>
            <div>
                {dashboardDetailData && <div>
                    <MaterialTable
                        icons={tableIcons}
                        title={''}
                        components={{
                            Toolbar: (_) => (
                                <div style={{ padding: 16 }}>
                                    <CSVLink filename={'acensa-metrics.csv'} style={{ textDecoration: 'none' }} data={dashboardDetailData}>
                                        <Button variant="outlined" startIcon={<Download />}>
                                            Download
                                        </Button>
                                    </CSVLink>
                                </div>

                            ),
                        }}
                        columns={columns}
                        data={dashboardDetailData}
                        options={{
                            sorting: true,
                            search: true,
                            pageSize: 25,
                            pageSizeOptions: [
                                25, 50, 100, 200
                            ]
                        }}
                    />
                </div>}
            </div>
        </StandardModal>
    )
}

export default DashboardDetails