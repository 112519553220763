
import { TableRow, TableCell, TableContainer, Paper, Table, TableBody, TableHead, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardDetails from '../../../components/dashboard/DashboardDetail';
import StandardModal from '../../../components/shared/StandardModal';
import { dateFormatted } from '../../../shared/utils/date-utils';
import { getDashboardData } from '../../../store/actions/userActions';

interface IAdminDashboard {

}

const AdminDashboard: React.FC<IAdminDashboard> = (props) => {
	const dispatch = useDispatch();
	const [dashboardData, setDashboardData] = useState<any>({});
	const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);

	useEffect(() => {
		dispatch(getDashboardData({}, (data: any) => {
			setDashboardData(data);
		}))
	}, []);

	const renderRow = (key: any, value: any) => {
		if (!isNaN(value)) {
			value = value.toFixed(2);
		}
		return (
			<TableRow
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell width={'50%'} align="left">{key}</TableCell>
				<TableCell width={'50%'} align="left">{value}</TableCell>
			</TableRow>
		)
	}

	return (<div>

		<h3>Dashboard</h3>

		<DashboardDetails
			open={detailModalOpen}
			setOpen={setDetailModalOpen}
		/>

		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell width={'40%'} align="left">Metric</TableCell>
						<TableCell width={'20%'} align="left">Value</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{renderRow('total_users', dashboardData?.total_users)}
					{renderRow('total_members', dashboardData?.total_members)}
					{renderRow('avg_non_user_members', dashboardData?.avg_non_user_members)}
					{renderRow('avg_prescription', dashboardData?.avg_prescription)}
					{renderRow('avg_appointment', dashboardData?.avg_appointment)}
					{renderRow('avg_journal', dashboardData?.avg_journal)}

				</TableBody>
			</Table>
		</TableContainer>

		<Box style={{ marginTop: 32 }} textAlign='center'>
				<Button variant="outlined" size="large" fullWidth={false}
					onClick={() => {
						setDetailModalOpen(true);
					}}
				>Detailed Metrics</Button>
			</Box>
	</div>)
};

export default AdminDashboard;
