export enum APP_BREAKPOINT {
    EXTRA_SMALL,
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE
}

export enum COLORS {
    WHITE_BACKGROUND = 'white',
    RED_BRAND_PRIMARY = '#ed1c24',
    BLUE_BRAND_PRIMARY = '#3b707a',
	GREEN_BRAND_PRIMARY = '#3fa57e',
    WHITE_PAPER = '#EDEDED',
    GREY_DARK = '#555555',
	WHEEL_2 = '#0099D1',
	WHEEL_3 = '#00BAC9',
	WHEEL_4 = '#00D6AA',
	WHEEL_5 = '#93EC85',
	WHEEL_6 = '#F9F871'

}

export const MARGIN_SMALL = 8
export const MARGIN_MEDIUM = 16
export const MARGIN_LARGE = 32