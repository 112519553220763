import React, { useState } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { createQuestionSet, updateQuestionSet } from "../../../store/actions/dataCollectionActions";
import Loading from "../../../components/general/Loading";
import StandardModal from "../../../components/shared/StandardModal";
import { MARGIN_SMALL } from "../../../enums/layout-enums";

interface IUpsertQuestionSet {
    mode: string
    setOpen: (dir: boolean) => void
    open: boolean
    initialValues: any
}

const validate = (values: any) => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.uuid) {
        errors.uuid = 'Required';
    }
    if (!values.description) {
        errors.description = 'Required';
    }
    return errors;
};

const UpsertQuestionSetModal: React.FC<IUpsertQuestionSet> = (props) => {

    const formik = useFormik({
        initialValues: props.initialValues,
        validate,
        onSubmit: values => {
            console.log("submitted")
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()

    const [processing, setProcessing] = useState(false)

    const closeModal = () => {
        setProcessing(false);
        props.setOpen(false)
    }

    const handleUpsertCall = () => {
        const upsertData = {...formik.values}
        delete upsertData.tableData
        if (props.mode === 'create') {
            dispatch(createQuestionSet(upsertData, (data: any) => {
                closeModal()
                enqueueSnackbar(
                    `Question set created.`,
                    { variant: "success" }
                );
            }, (data: any) => {
                setProcessing(false)
                enqueueSnackbar(
                    `Problem creating question set.`,
                    { variant: "error" }
                );
            }))
        }
        else {
            dispatch(updateQuestionSet(upsertData, (data: any) => {
                closeModal()
                enqueueSnackbar(
                    `Question set updated.`,
                    { variant: "success" }
                );
            }, (data: any) => {
                console.log("error data", data)
                setProcessing(false)
                enqueueSnackbar(
                    data.toString(),
                    { variant: "error" }
                );
            }))
        }
    }

    return (
        <>
            <Loading loading={processing} />
            <StandardModal
                title={props.mode === 'create' ? 'Create Question Set' : 'Edit Question Set'}
                open={props.open}
                onClose={() => props.setOpen(false)}
                actions={[{
                    title: props.mode === 'create' ? 'Create' : 'Save',
                    callback: () => {
                        formik.validateForm(formik.values).then((errors) => {
                            if (Object.keys(errors).length === 0) {
                                setProcessing(true)
                                handleUpsertCall()
                            }
                        })
                    }
                }]}
            >
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} margin={'dense'} error={formik.errors.name ? true : false} variant="standard">
                                    <TextField
                                        label="Name"
                                        fullWidth={true}
                                        multiline={true}
                                        size="small"
                                        name="name"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.name}
                                        variant="outlined"
                                    />
                                    {formik.errors.name ? <FormHelperText id="component-error-text">{formik.errors.name as string}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} margin={'dense'} error={formik.errors.uuid ? true : false} variant="standard">
                                    <TextField
                                        label="UUID"
                                        fullWidth={true}
                                        multiline={true}
                                        size="small"
                                        name="uuid"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.uuid}
                                        variant="outlined"
                                    />
                                    {formik.errors.uuid ? <FormHelperText id="component-error-text">{formik.errors.uuid as string}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} margin={'dense'} error={formik.errors.description ? true : false} variant="standard">
                                    <TextField
                                        label="Description"
                                        fullWidth={true}
                                        multiline={true}
                                        size="small"
                                        name="description"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.description}
                                        variant="outlined"
                                    />
                                    {formik.errors.description ? <FormHelperText id="component-error-text">{formik.errors.description as string}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>

                </div>

            </StandardModal>
        </>

    );

}
export default UpsertQuestionSetModal;