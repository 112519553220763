import { normalize, schema } from 'normalizr';
import { all, call, put } from 'redux-saga/effects';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { dateFormatted } from '../../shared/utils/date-utils';
import GetFriendlyError from '../../utils/general';
import fileSaver from "file-saver";
import { dashboardDataSchema, userSchema, userNotesSchema, roleSchema, userRoleSchema } from './schema';


const client = new HttpClient();

async function downloadUsersReportAPI(data: any) {
	try {
		const response = await client.getFile('api/user/download-users-report' + objectToQuery(data));
		return response;
	} catch (e) {
		return e;
	}
}

async function userActivationAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.get('api/user/activation' + objectToQuery(data));
}

// async function getUserAPI(
// 	data: any,
// ): Promise<HttpResponse<{ x: string }>> {
// 	return client.get('api/user/get_user' + objectToQuery(data));
// }

async function updateUserAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.put('api/user/'+data.id, data);
}

async function getUserNotesAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.get('api/user/notes' + objectToQuery(data));
}

async function createUserNoteAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.post('api/user/notes', data);
}

async function updateUserNoteAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.put('api/user/notes/'+data.id, {note: data.note});
}

async function deleteUserNoteAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.delete('api/user/notes/'+data.id);
}

async function getUsersAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.get('api/user/get_all', data);
}

async function userInStoreAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.post('api/user/in_store', data);
}

async function simulateUserOrderAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.get(`api/user/random_order?user_id=` + data.user_id, data);
}

async function getDashboardDataAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.get('api/admin/get_dashboard_data', data);
}

async function addRemoveRoleAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.post('api/user/add_remove_role', data);
}


const userSaga = {
	* getDashboardData(action: IDispatchAction): Generator {
		try {
			const response = (yield call(getDashboardDataAPI, action.payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {

				yield all([
					put({
						type: 'GET_DASHBOARD_DATA_SUCCESS',
						normalized: normalize({ uuid: 'order_summary_daily', data: response.data.order_summary_daily.flex_json }, dashboardDataSchema),
					}),
					put({
						type: 'GET_DASHBOARD_DATA_SUCCESS',
						normalized: normalize({ uuid: 'top_products', data: response.data.top_products.flex_json }, dashboardDataSchema),
					}),
				]);
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* userActivation(action: IDispatchAction): Generator {
		try {
			const response = (yield call(userActivationAPI, action.payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {
				yield put({
					type: 'USER_ACTIVATION_SUCCESS',
					normalized: normalize(response.data.user, userSchema),
				})
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* simulateUserOrder(action: IDispatchAction): Generator {
		try {
			const response = (yield call(simulateUserOrderAPI, action.payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {
				if (action.onSuccess) {
					action.onSuccess(response);
				}
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail(e);
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* toggleUserInStore(action: IDispatchAction): Generator {
		try {
			const response = (yield call(userInStoreAPI, action.payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {
				yield put({
					type: 'TOGGLE_USER_IN_STORE_SUCCESS',
					normalized: normalize(response.data.user, userSchema),
				})
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	// * getUser(action: IDispatchAction): Generator {
	// 	try {
	// 		const payload = action.payload as any;
	// 		const response = (yield call(getUserAPI, payload)) as HttpResponse<any>;
	// 		if (response.status === 200 || response.status === 201) {
	// 			yield all([
	// 				put({
	// 					type: 'GET_USER_SUCCESS',
	// 					normalized: normalize(response.data.user, usersSchema),
	// 				})
	// 			])
	// 			if (action.onSuccess) {
	// 				action.onSuccess();
	// 			}
	// 		}
	// 	} catch (e) {
	// 		if (action.onFail) {
	// 			action.onFail();
	// 		}
	// 		const error = e as Error;
	// 		const friendlyError = GetFriendlyError(error.message);
	// 		yield put({ type: ACTIONS.ERROR, message: friendlyError });
	// 	}
	// },

	* updateUser(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(updateUserAPI, payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201 || response.status === 304) {
				yield all([
					put({
						type: 'UPDATE_USER_SUCCESS',
						normalized: normalize(response.data, userSchema)
					})
				])
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			const error = e as Error;
			if (action.onFail) {
				action.onFail(error.message);
			}
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* getUserNotes(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(getUserNotesAPI, payload)) as HttpResponse<any>;
			console.log("user note response", response);
			if (response.status === 200 || response.status === 201 || response.status === 304) {
				yield all([
					put({
						type: 'GET_USER_NOTE_SUCCESS',
						payload:  normalize(response.data.userNotes, [userNotesSchema]).entities.user_notes || []
					})
				])
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* createUserNote(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(createUserNoteAPI, payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201 || response.status === 304) {
				yield all([
					put({
						type: 'CREATE_USER_NOTE_SUCCESS',
						normalized: normalize(response.data, userNotesSchema)
					})
				])
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* updateUserNote(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(updateUserNoteAPI, payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201 || response.status === 304) {
				yield all([
					put({
						type: 'UPDATE_USER_NOTE_SUCCESS',
						normalized: normalize(response.data, userNotesSchema)
					})
				])
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* deleteUserNote(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(deleteUserNoteAPI, payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201 || response.status === 304) {
				yield all([
					put({
						type: 'DELETE_USER_NOTE_SUCCESS',
						normalized: normalize(response.data, userNotesSchema)
					})
				])
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},


	* getUsers(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(getUsersAPI, payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {
				yield put({
					type: 'GET_USERS_SUCCESS',
					overrides: [
						{
							key: "users",
							value: normalize(response.data.users, [userSchema]).entities.users || {}
						},
						{
							key: "roles",
							value: normalize(response.data.roles, [roleSchema]).entities.roles || {}
						},
						{
							key: "user_roles",
							value: normalize(response.data.userRoles, [userRoleSchema]).entities.user_roles || {}
						}
					]
				});
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* downloadUsersReport(action: IDispatchAction): Generator {
		try {

			const response = (yield call(downloadUsersReportAPI, action.payload)) as HttpResponse<any>;;
			let filename = `user-report-${dateFormatted(new Date(), 'yyyymmdd', '-')}.csv`;
			fileSaver.saveAs(response.data as any, filename);
			if (action.onSuccess) {
				action.onSuccess();
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	
	* addRemoveRole(action: IDispatchAction): Generator {
		try {
			const payload = action.payload as any;
			const response = (yield call(addRemoveRoleAPI, payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {
				//TODO, this should return and add or remove user_roles
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		} catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

};

export default userSaga;
