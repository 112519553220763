
import * as React from 'react';
import { Button, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { COLORS, MARGIN_LARGE, MARGIN_MEDIUM } from '../../../enums/layout-enums';
import UserTable from '../../../components/user/UserTable';
import { downloadUsersReport, getUsers } from '../../../store/actions/userActions';
import { dateFormatted } from '../../../shared/utils/date-utils';
import Download from '@mui/icons-material/Download';
import Switch from '@mui/material/Switch';
const authContStyle = {
	marginTop: 16, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
}

const iconStyle = {
	color: COLORS.BLUE_BRAND_PRIMARY, fontSize: 24
}

function Users(props: any) {

	const [filterAdmins, setFilterAdmins] = useState(false);

	const users = useSelector(
		(state: IRootState) => state.main.users
	);

	const roles = useSelector(
		(state: IRootState) => state.main.roles
	);

	const userRoles = useSelector(
		(state: IRootState) => state.main.user_roles
	);

	const [adminUsers, setAdminUsers] = useState<any[]>([])

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUsers({}, () => {

		}))
	}, [])

	useEffect(() => {

		const adminUserTemp = [] as any[]
		const allUsersArr = [...Object.values(users)] as any[]
		const rolesArr = Object.values(roles);
		const userRolesArr = Object.values(userRoles);

		allUsersArr.forEach((pUser) => {
			if (pUser.last_visit) {
				pUser.last_visit = dateFormatted(pUser.last_visit, "mmddyyyy")
			}
			const roleNames = [] as any[]
			const pUserRoles = userRolesArr.filter((x) => x.user_id === pUser.id);
			pUserRoles.forEach((pUserRole) => {
				const pRole = rolesArr.find((x) => x.id === pUserRole.role_id);
				roleNames.push(pRole?.name);
				if (pRole?.name === 'Admin') {
					adminUserTemp.push({
						...pUser
					})
				}
			})
			if (roleNames) {
				pUser.roleString = roleNames.join(', ')
			}
			else {
				pUser.roleString = 'User'
			}
		})
		setAdminUsers(adminUserTemp);
	}, [users, roles, userRoles])


	const downloadUsers = () => {
		dispatch(downloadUsersReport({

		}))
	}

	return <>
		{/* {preparedUsers.length > 0 && <div key={preparedUsers.length}> */}
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>
				<FormGroup>
					<FormControlLabel control={<Switch
						checked={filterAdmins}
						onChange={(e) => setFilterAdmins(e.target.checked)}
						inputProps={{ 'aria-label': 'controlled' }}
					/>} label="Filter Admins" />
				</FormGroup>

			</div>
			<div style={{ flex: 1, textAlign: 'right' }}>
				<Button onClick={() => {
					downloadUsers()

				}} variant='outlined' startIcon={<Download />}>
					Download
				</Button>

			</div>

		</div>
		<UserTable key={filterAdmins.toString()} title={'Users'}  fixed_query_params={filterAdmins? { role: 'Admin' } : {}} />
		{/* <div style={{ marginTop: MARGIN_LARGE }} >
			<UserTable title={'Admin Users'} fixed_query_params={{ role: 'Admin' }} />

		</div> */}

		{/* </div>} */}
	</>
}

export default Users