import { ACTIONS } from "../../enums/actions";


export const createDataCollection = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_USER_ROLES,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const createQuestionSet = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.CREATE_QUESTION_SET,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const createQuestion = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.CREATE_QUESTION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateQuestionSet = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.UPDATE_QUESTION_SET,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateQuestion = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.UPDATE_QUESTION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getQuestionSets = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_QUESTION_SETS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getQuestions = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_QUESTIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getMyDataCollections = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_MY_DATA_COLLECTIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getDataCollectionsByUserId = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_DATA_COLLECTIONS_BY_USER_ID,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getAllQuestionSetsAndQuestions = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_ALL_QUESTION_SETS_AND_QUESTIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});