
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, FormControl, FormHelperText, IconButton, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { COLORS, MARGIN_MEDIUM } from '../../../enums/layout-enums';
import { getUser, updateUser } from '../../../store/actions/userActions';
import { dateFmt, dateFormatted } from '../../../shared/utils/date-utils';
import { CSVLink } from 'react-csv';
import Download from '@mui/icons-material/Download';
import Loading from '../../../components/general/Loading';
import { Close, Edit, Check } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { IUser } from '../../../shared/interfaces/model/user.interface';
import { IMember } from '../../../shared/interfaces/model/member.interface';
import { IDataCollection } from '../../../shared/interfaces/model/data-collection.interface';
import { getAllQuestionSetsAndQuestions, getDataCollectionsByUserId } from '../../../store/actions/dataCollectionActions';
import { IQuestionSet } from '../../../shared/interfaces/model/question-set.interface';
import { IQuestion } from '../../../shared/interfaces/model/question.interface';

const authContStyle = {
  marginTop: 16, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
}

const iconStyle = {
  color: COLORS.BLUE_BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


function Users(props: any) {

  const params = useParams<{ userId: string }>()
  const dispatch = useDispatch();
  const history = useNavigate();
  const [user, setUser] = useState({} as IUser)
  const [member, setMember] = useState({} as IMember)
  const [editedUser, setEditedUser] = useState({} as any)
  const [userFetched, setUserFetched] = useState(false)
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [questionAnswersReady, setQuestionsAnswersReady] = useState(false);
  const [preparedDataCollections, setPreparedDataCollections] = useState({} as any);

  useEffect(() => {
    if (!isNaN(Number(params.userId))) {
      dispatch(getUser({ user_id: params.userId }, () => {
        setUserFetched(true)
        dispatch(getAllQuestionSetsAndQuestions({}, () => {
          dispatch(getDataCollectionsByUserId({ user_id: params.userId }, () => {
            setQuestionsAnswersReady(true)
          }))
        }))
      }))

    }
  }, [])

  const users = useSelector(
    (state: IRootState) => state.main.users
  );

  const members = useSelector(
    (state: IRootState) => state.main.members
  )

  const dataCollectionsStore = useSelector(
    (state: IRootState) => state.main.data_collections
  )

  const questionsStore = useSelector(
    (state: IRootState) => state.main.questions
  )

  const questionSetStore = useSelector(
    (state: IRootState) => state.main.question_sets
  )

  useEffect(() => {
    if (questionAnswersReady === true) {
      const preparedSets = {
      } as any
      const userDataCollections = Object.values(dataCollectionsStore).filter((x) => x.user_id === Number(params.userId));
      const questions = Object.values(questionsStore);
      const questionSets = Object.values(questionSetStore);
      questions.forEach((qq, index) => {
        const matchedUserDataCollection = userDataCollections.find((userDataCollection) => userDataCollection.question_id === qq.id);
        if (matchedUserDataCollection) {
          if (!preparedSets[qq.question_set_id]) {
            preparedSets[qq.question_set_id] = questionSets.find((qs) => qs.id === qq.question_set_id)
          }
          if (!preparedSets[qq.question_set_id].questions) {
            preparedSets[qq.question_set_id].questions = {}
          }
          if (!preparedSets[qq.question_set_id].questions[qq.id!]) {
            preparedSets[qq.question_set_id].questions[qq.id!] =
            {
              question: qq,
              answer: matchedUserDataCollection
            }
          }
        }
      })
      setPreparedDataCollections(preparedSets)
    }
  }, [questionAnswersReady])

  useEffect(() => {
    if (userFetched) {
      const matchedUser = Object.values(users).find((x) => x.id === Number(params.userId)) as any
      if (!matchedUser) {
        history('/Users')
      }
      else {
        const matchedMember = Object.values(members).find((x) => x.user_id === matchedUser.id) as IMember
        if (matchedMember) {
          setMember(matchedMember)
        }
        matchedUser.last_visit = dateFormatted(matchedUser.last_visit, "mmddyyyy")
        const roleNames = [] as any[]
        const pUserRoles = Object.values(userRoles).filter((x) => x.user_id === matchedUser.id);
        pUserRoles.forEach((pUserRole) => {
          const pRole = Object.values(roles).find((x) => x.id === pUserRole.role_id);
          roleNames.push(pRole?.name);
        })
        if (roleNames) {
          matchedUser.roleString = roleNames.join(', ')
        }
        else {
          matchedUser.roleString = 'Customer'
        }
        setUser(matchedUser)
        setEditedUser(matchedUser)
        setLoading(false);

      }
    }


  }, [users, userFetched])


  const roles = useSelector(
    (state: IRootState) => state.main.roles
  );

  const userRoles = useSelector(
    (state: IRootState) => state.main.user_roles
  );

  const validateUserEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setEmailError("Incorrect format for Email Address.");
    }
    else {
      setEmailError("");
    }
  }
  const updateEditedUser = (key: string, value: any) => {
    setEditedUser({ ...editedUser, [key]: value })
  }

  const renderQuestions = (questionsStore: any) => {
    const questionsArr = Object.values(questionsStore);

    return questionsArr.map((questionCur: any, index) => {
      const question = questionCur.question as IQuestion;
      const answer = questionCur.answer as IDataCollection;

      let answer_info = ''
      let answer_simple = '';
      const created_at = answer.created_at!;
      switch (question.value_type) {
        case 'NUMBER':
          answer_simple = answer.numeric_value!.toString();
          break;
        case 'NUMBER_WITH_TEXT_ALIASES':
          if (question?.data?.aliases){
            const matchedAlias = question?.data?.aliases.find((x: any) => x.number === answer.numeric_value!)
            if (matchedAlias) {
              answer_info = matchedAlias.val
            }
          }
          answer_simple = answer.numeric_value!.toString(); //TODO get the answer alias
          break;
        case 'BOOLEAN':
          answer_simple = answer.boolean_value!.toString();
          break;
      }

      return (
        <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell width={'40%'}  align="left">{question.question_detail}</TableCell>
          <TableCell width={'20%'}  align="left">{answer_simple}</TableCell>
          <TableCell width={'20%'}  align="left">{answer_info}</TableCell>
          <TableCell width={'20%'}  align="left">{dateFormatted(created_at, 'yyyymmdd')}</TableCell>
        </TableRow>

      )
    })
  }

  const renderQuestionSets = () => {
    const questionSets = Object.values(preparedDataCollections);
    return questionSets.map((questionSetCur: any, index) => {
      const questionSet = questionSetCur as any;
      const name = questionSet.name;
      return (
        <div key={index}>
          <h3>{name}</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={'40%'} align="left">Question</TableCell>
                  <TableCell width={'20%'} align="left">Answer</TableCell>
                  <TableCell width={'20%'}  align="left">Info</TableCell>
                  <TableCell width={'20%'}  align="left">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderQuestions(questionSet.questions)}

              </TableBody>
            </Table>
          </TableContainer>

        </div>
      )
    })
  }

  <Loading loading={loading} />

  if (loading) {
    return <div></div>
  }
  return (
    <div key={member?.id}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <b>User Info</b>
        <Box>
          {
            !editing ?
              <IconButton
                style={iconStyle}
                edge="end"
                aria-label="Edit"
                onClick={() => {
                  setEditing(true);
                }}
              >
                <Edit />
              </IconButton>
              :
              <>
                <IconButton
                  style={iconStyle}
                  edge="end"
                  aria-label="Close"
                  onClick={() => {
                    setEditing(false);
                    setEditedUser(user)
                    setEmailError("");
                    enqueueSnackbar(
                      "Changes Reverted",
                      { variant: "default" }
                    )
                  }}
                >
                  <Close color="disabled" />
                </IconButton>
                <IconButton
                  style={iconStyle}
                  edge="end"
                  aria-label="Edit"
                  onClick={() => {
                    let updatedUser = {
                      id: editedUser.id,
                      email: editedUser.email,
                      first_name: editedUser.first_name,
                      last_name: editedUser.last_name,
                      phone: editedUser.phone,
                      birthdate: editedUser.birthdate
                    }

                    if (!emailError) {
                      dispatch(updateUser(updatedUser, () => {
                        setEditing(false);
                        enqueueSnackbar(
                          "User Saved",
                          { variant: "success" }
                        )
                      },
                        (test) => {
                          enqueueSnackbar(
                            test,
                            { variant: "error" }
                          )
                        }

                      ))
                    }
                    else {
                      enqueueSnackbar(
                        "Improper Email Format",
                        { variant: "error" }
                      )
                    }


                  }}

                >
                  <Check />
                </IconButton>
              </>
          }

        </Box>
      </Box>


      <hr />
      <br />



      <div>First Name: {member?.first_name}</div>
      <div>Last Name: {member?.last_name}</div>
      {!editing ?
        <div>Email: {user?.email}</div>
        :
        <>
          Email:

          <FormControl error={emailError ? true : false} variant="standard">
            <Input
              id="component-error"
              value={editedUser?.email}
              onChange={(e) => {
                validateUserEmail(e.target.value);
                updateEditedUser("email", e.target.value);
              }}
              aria-describedby="component-error-text"
            />
            {emailError ? <FormHelperText id="component-error-text">{emailError}</FormHelperText> : null}
          </FormControl>

        </>

      }
      <div>Phone: {user?.phone}</div>
      {/* <pre>{JSON.stringify(user,null,2)}</pre> */}
      <br />
      <br />

      {/* <pre>{JSON.stringify(userOrders,null,2)}</pre> */}
      {/* <div>{dataCollections.map((dCollection, index) => {
          return (
            <div key={index}>
              <pre>{JSON.stringify(dCollection,null,2)}</pre>
            </div>
          )
        })}</div> */}
      {renderQuestionSets()}
    </div>
  )
}

export default Users
