import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IRootState } from './interfaces/store';
import AppAuthenticated from './AppAuthenticated';
import AppPublic from './AppPublic';
import './App.css';
import ErrorBoundary from './components/shared/ErrorBoundary';


function App(props: any) {

	const email = useSelector(
		(state: IRootState) => state.main.my_user?.email
	);

	return (
		<ErrorBoundary>
		<div key={email}>
			{email ? <AppAuthenticated /> : <AppPublic />}
		</div>
		</ErrorBoundary>
	);
}

App.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default App;