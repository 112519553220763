
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material';
import { COLORS, MARGIN_MEDIUM } from "../../../enums/layout-enums";
import QuestionSetTable from "../../../components/data-collection/QuestionSetTable";
import UpsertQuestionSetModal from "./UpsertQuestionSet";
import { useParams } from "react-router-dom";
import { useSelector } from "../../../store";
import { IQuestionSet } from "../../../shared/interfaces/model/question-set.interface";
import UpsertQuestionModal from "./UpsertQuestion";
import { DATA_RESPONSE_TYPE } from "../../../shared/constants/data-collection.constants";
import QuestionTable from "../../../components/data-collection/QuestionTable";
import { Download } from "@mui/icons-material";

interface IQuestionSetPage {
}

const initialQuestionState = {
	name: '',
	question_detail: '',
	value_type: null,
	min: 0,
	max: 10
}

const ReleaseNotes: React.FC<IQuestionSetPage> = (props) => {

	const params = useParams<{ questionSetId: string }>()


	const question_sets = useSelector(
		(state) => state.main.question_sets
	)

	const [questionSet, setQuestionSet] = useState<IQuestionSet | null>(null);
	const [upsertQuestionMode, setUpsertQuestionMode] = useState('create');
	const [upsertQuestionOpen, setUpsertQuestionOpen] = useState(false);
	const [upsertQuestionInitialState, setUpsertQuestionInitialState] = useState(initialQuestionState);
	const [upsertQuestionOpenCount, setUsertQuestionOpenCount] = useState(0)
	const [filterActive, setFilterActive] = useState(true)

	useEffect(() => {
		const question_sets_arr = Object.values(question_sets)
		const matched = question_sets_arr.find((x) => x.id === Number(params.questionSetId))
		if (matched) {
			setQuestionSet(matched)
		}
	}, [question_sets])

	const handleOpenCreateQuestion = () => {
		setUpsertQuestionMode('create')
		setUpsertQuestionInitialState(initialQuestionState)
		setUsertQuestionOpenCount(upsertQuestionOpenCount + 1)
		setUpsertQuestionOpen(true)
	}

	const downloadQuestions = () => {
		console.log("todo")
	}

	const renderQuestionSetData = () => {
		if (!questionSet) {
			return null;
		}
		return (
			<div>
				Name: {questionSet.name}
			</div>
		)
	}

	return (
		<div>



			<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
				<div style={{ flex: 1, textAlign: 'left' }}>
					<Button
						style={{ marginBottom: MARGIN_MEDIUM }}
						variant="contained"
						type="submit"
						onClick={() => {
							handleOpenCreateQuestion()
						}
						}
					>Create
					</Button>


				</div>
				<div style={{ flex: 1, textAlign: 'right', display: 'inline-block' }}>
					<FormGroup style={{ display: 'inline-block' }}>
						<FormControlLabel control={<Switch
							checked={filterActive}
							onChange={(e) => setFilterActive(e.target.checked)}
							inputProps={{ 'aria-label': 'controlled' }}
						/>} label="Filter Active" />
					</FormGroup>
					<Button onClick={() => {
						downloadQuestions()

					}} variant='outlined' startIcon={<Download />}>
						Download
					</Button>

				</div>

			</div>




			<UpsertQuestionModal
				key={upsertQuestionOpenCount}
				mode={upsertQuestionMode}
				setOpen={setUpsertQuestionOpen}
				open={upsertQuestionOpen}
				initialValues={upsertQuestionInitialState}
				questionSetId={Number(params.questionSetId)}
			/>
			<QuestionTable
				questionSetId={Number(params.questionSetId)}
				filterActive={filterActive}
			/>


		</div>
	)

}
export default ReleaseNotes