
import { all, call, put } from "redux-saga/effects"; //call
import { ACTIONS } from "../../enums/actions";
import { IDispatchAction } from "../../interfaces/store";
import { HttpResponse } from "../../interfaces/store/api/HttpResponse";
import GetFriendlyError from "../../utils/general";
import { autoYields } from './schema'
import HttpClient, { objectToQuery } from '../../api/HttpClient';

const client = new HttpClient();


export async function genericGet(
	data: any,
    url: string
): Promise<HttpResponse<{ x: string }>> {
	return client.get(url + objectToQuery(data));
}

export async function genericPost(
	data: any,
	url: string
): Promise<HttpResponse<{ x: string }>> {
	return client.post(url, data);
}

const genericSaga = {

* genericHandler(action: any, apiEndpoint: any): Generator{
    console.log("genericHandler Action", action)
    try {
        const response = (yield call(apiEndpoint, action.payload)) as HttpResponse<any>;
        if (response.status === 200 || response.status === 201) {
            console.log("genericHandler response was success", response)
            const yields = autoYields(response.data);
            if (yields.length > 0){
                yield all(yields)
            }
            if (action.onSuccess) {
                console.log("call it with this data?", response.data)
                console.log("the action?", action.onSuccess);
                action.onSuccess(response.data);
            }
        }
        else{
            throw (response.status)
        }
    }
    catch (e) {
        if (action.onFail) {
            action.onFail(e);
        }
        const error = e as Error;
        const friendlyError = GetFriendlyError(error.message);
        yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
},

}

export default genericSaga;