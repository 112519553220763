import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IRootState } from "../../../interfaces/store";
import { useSelector } from "../../../store";
import { login } from "../../../store/actions/authActions";
import { useSnackbar } from "notistack";
import { useNavigate  } from "react-router";

interface ILoginProps {}

const Login: React.FC<ILoginProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(
    (state: IRootState) => state.main.my_user
  );
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleForgotPassword = () => {
    history(`/ForgotPassword`);
  };

  const handleLogin = () => {
    dispatch(
      login(
        { email, password },
        () => {
          enqueueSnackbar("Login Success", { variant: "success" });
          history(`/Dashboard`);
        },
        () =>
		{
          enqueueSnackbar("Could not find email / password.", {
            variant: "error",
          })
		}
      )
    );
  };

  return (
    <>
      <Card style={{ width: 400 }}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Login
          </Typography>
          <div style={{ marginTop: 16 }}>
            <TextField
              label="Email"
              size="small"
              fullWidth={true}
              id="3_citation_no"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event?.target.value)}
              InputLabelProps={{
                shrink: true
             }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              label="Password"
              size="small"
              fullWidth={true}
              id="3_citation_no"
              variant="outlined"
              type="password"
              value={password}
              onChange={(event) => setPassword(event?.target.value)}
              InputLabelProps={{
                shrink: true
             }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <Button variant={'outlined'} onClick={() => handleLogin()}>Submit</Button>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button onClick={() => handleForgotPassword()} size="small">
              Forgot Password?
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Login;
