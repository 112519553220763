import React from "react";
import initializeReduxStorage from "./store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { COLORS } from "./enums/layout-enums";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material";

const store = initializeReduxStorage();

const Main: React.FC = () => {
	document.body.style.backgroundColor = COLORS.WHITE_BACKGROUND;
	const notistackRef = React.createRef<any>();

	const onClickDismiss = (key: any) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const theme = createTheme({
		palette: {
			primary: {
				main: COLORS.BLUE_BRAND_PRIMARY
			}
		}
	});

	return (
		<Provider store={store}>
			{/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
			<ThemeProvider theme={theme}>
				{/* <LocalizationProvider dateAdapter={DateAdapter}> */}
				<SnackbarProvider
					ref={notistackRef}
					maxSnack={3}
					autoHideDuration={3000}
					anchorOrigin={{ horizontal: "right", vertical: "top" }}
					action={(key) => (
						<span></span>
					)}
				>
					<BrowserRouter>
					<App />

					</BrowserRouter>
				</SnackbarProvider>
				{/* </MuiPickersUtilsProvider> */}
				{/* </LocalizationProvider> */}
			</ThemeProvider>
		</Provider>
	);
};

export default Main;
