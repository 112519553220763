import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./actionFunctions";

export const getUser = genericAction(ACTIONS.GET_USER);
export const getUserNotes = genericAction(ACTIONS.GET_USER_NOTES);
export const createUserNote = genericAction(ACTIONS.CREATE_USER_NOTE);
export const updateUserNote = genericAction(ACTIONS.UPDATE_USER_NOTE);
export const deleteUserNote = genericAction(ACTIONS.DELETE_USER_NOTE);
export const getUsers = genericAction(ACTIONS.GET_USERS);
export const updateUser = genericAction(ACTIONS.UPDATE_USER);
export const userActivation = genericAction(ACTIONS.USER_ACTIVATION);
export const getDashboardData = genericAction(ACTIONS.GET_DASHBOARD_DATA);
export const downloadUsersReport = genericAction(ACTIONS.DOWNLOAD_USERS_REPORT);
export const addRemoveRole = genericAction(ACTIONS.ADD_REMOVE_ROLE);