
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { COLORS } from "../../../enums/layout-enums";

interface IReleaseNotes {
}

export const release_notes = [
	{
		date: '2022-09-16',
		version: '0.0.6',
		environment: 'beta',
		notes: [
			{
				area: 'Upsert Question',
				note: 'Improved the UI of creating and updating questions.',
			},
			{
				area: 'Prescription',
				note: 'Responses now include number left and if the quantity is low.',
			}
		]
	},
	{
		date: '2022-09-02',
		version: '0.0.5',
		environment: 'beta',
		notes: [
			{
				area: 'View User',
				note: 'Added question and answers that the user has responded to.',
			},
			{
				area: 'View User',
				note: 'Fixed issue where member data would not populate to the user.',
			}
		]
	},
	{
		date: '2022-06-29',
		version: '0.0.4',
		environment: 'beta',
		notes: [
			{
				area: 'Profile Upload',
				note: 'Upload for member profile supported with secure download links.',
			},
			{
				area: 'Authentication',
				note: 'Refresh tokens now return member data in addition to user data.',
			}
		]
	},
	{
		date: '2022-06-16',
		version: '0.0.3',
		environment: 'beta',
		notes: [
			{
				area: 'Question Set',
				note: 'Questionnaire section with question set table, filter, search and ability to perform question set crud.',
			},
			{
				area: 'Question',
				note: 'Question section with question table, filter, search and ability to perform question set crud.',
			},
		]
	},
	{
		date: '2022-05-29',
		version: '0.0.2',
		environment: 'beta',
		notes: [
			{
				area: 'General',
				note: 'Release note link added to authenticated login app bar.',
			},
			{
				area: 'Wallet',
				note: 'Test wallet resource uploading feature implemented.',
			}
		]
	},
	{
		date: '2022-05-15',
		version: '0.0.1',
		environment: 'beta',
		notes: [
			{
				area: 'General',
				note: 'Admin panel added, with login and generic dashboard.',
			},
			{
				area: 'Users',
				note: 'User system added with searchable user table.',
			},
			{
				area: 'Users',
				note: 'Added ability to promote and demote admin user status.',
			}
		]
	}
]

const getEnvironmentDiv = (env: string) => {
	if (env === 'beta' || env === 'development' || env === 'staging') {
		return <span>{env}</span>
	}
	return null;
}

const ReleaseNotes: React.FC<IReleaseNotes> = (props) => {

	return (
		<div>
			<h2>History</h2>
			<h1>Version: {release_notes[0].version}</h1>
			{release_notes.map((rn, rnIndex) => {
				return (
					<div key={rnIndex}>
						<section>
							<article>
								Version {rn.version} ({getEnvironmentDiv(rn.environment)})
								<br />
								Release Date: {rn.date}
								<ul>
									{rn.notes.map((note, noteIndex) => {
										return (
											<li key={noteIndex}>{note.area}: {note.note}</li>
										)
									})}
								</ul>
							</article>
						</section>
						<hr></hr>
					</div>
				)
			})}
		</div>

	)

}
export default ReleaseNotes