
import { Typography, Button, List, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { createUserNote, getUserNotes, updateUserNote, deleteUserNote } from "../../store/actions/userActions";
import UserNote from "./UserNote";






interface IUserNotesProps {
	user_id: number;
	location_id: number;
}


const UserNotes: React.FC<IUserNotesProps> = (props) => {
	const dispatch = useDispatch();
	const [notesLoaded, setNotesLoaded] = useState(false);
	let userNotes = Object.values(useSelector((state: IRootState) => state.main.user_notes));
	const [editFirst, setEditFirst] = useState(false);

	userNotes = userNotes.filter(note => !note.deleted);



	const loadNotes = () => {
		dispatch(getUserNotes({ user_id: props.user_id, location_id: props.location_id }, () => {
			setNotesLoaded(true);
		}))
	}

	const updateNote = (note: string, id?: number) => {
		if (id) {
			dispatch(updateUserNote({ id: id, note: note }, () => {
				setEditFirst(false);
			}));
		}
	}

	const deleteNote = (id?: number) => {
		if (id) {
			dispatch(deleteUserNote({ id: id }, () => {
				setEditFirst(false);
			}));
		}
	}

	const setFirstToEditing = (noteIndex: number): Boolean => {
		if (noteIndex == 0 && editFirst) {
			return true;
		}
		else {
			return false;
		}
	}


	useEffect(() => {
		loadNotes()
	}, [])
	return (
		<>
			<Box style={{ marginTop: "10px" }} textAlign='center'>
				<Button variant="contained" size="large" fullWidth={false}
					onClick={() => {
						dispatch(createUserNote({ note: "Empty Note", location_id: props.location_id, user_id: props.user_id }, () => {
							setEditFirst(true)
						}))
					}}
				>Add Note</Button>
			</Box>
			<List>

				<Divider style={{ marginTop: "20px" }} light component="li" />
				{notesLoaded && userNotes ?
					userNotes.length > 0 ?
						userNotes.slice(0).reverse().map((note, i) =>
							!note.deleted ?
								<UserNote dateCreated={note.created_at} updateNote={(newNote: string) => updateNote(newNote, note.id)} deleteNote={() => deleteNote(note.id)} noEditMade={() => setEditFirst(false)} key={i} note={note.note} editing={setFirstToEditing(i)} />
								: null
						)
						:
						<Typography variant='h6' align="center" style={{ marginTop: "20px" }}>There are no notes for this user.</Typography>
					: "Loading Notes"}
			</List>
		</>
	)
}

export default UserNotes;
