
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material';
import { COLORS, MARGIN_MEDIUM } from "../../../enums/layout-enums";
import QuestionSetTable from "../../../components/data-collection/QuestionSetTable";
import UpsertQuestionSetModal from "./UpsertQuestionSet";
import { Download } from "@mui/icons-material";

interface IDataCollection {
}

const initialQuestionSetState = {
	name: '',
	uuid: '',
	description: ''
}

const ReleaseNotes: React.FC<IDataCollection> = (props) => {

	const [upsertQuestionSetMode, setUpsertQuestionSetMode] = useState('create');
	const [upsertQuestionSetOpen, setUpsertQuestionSetOpen] = useState(false);
	const [upsertQuestionSetInitialState, setUpsertQuestionSetInitialState] = useState(initialQuestionSetState);
	const [upsertQuestionSetOpenCount, setUsertQuestionSetOpenCount] = useState(0)
	const [filterActive, setFilterActive] = useState(true)

	const handleOpenCreateQuestionSet = () => {
		setUpsertQuestionSetMode('create')
		setUpsertQuestionSetInitialState(initialQuestionSetState)
		setUsertQuestionSetOpenCount(upsertQuestionSetOpenCount + 1)
		setUpsertQuestionSetOpen(true)
	}

	const downloadQuestionSets = () => {
		console.log("TODO")
	}

	return (
		<div>
			<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
				<div style={{ flex: 1, textAlign: 'left' }}>
					<Button
						style={{ marginBottom: MARGIN_MEDIUM }}
						variant="contained"
						type="submit"
						onClick={() => {
							handleOpenCreateQuestionSet()
						}
						}
					>Create
					</Button>


				</div>
				<div style={{ flex: 1, textAlign: 'right', display: 'inline-block' }}>
					<FormGroup style={{display: 'inline-block'}}>
						<FormControlLabel control={<Switch
							checked={filterActive}
							onChange={(e) => setFilterActive(e.target.checked)}
							inputProps={{ 'aria-label': 'controlled' }}
						/>} label="Filter Active" />
					</FormGroup>
					<Button onClick={() => {
						downloadQuestionSets()

					}} variant='outlined' startIcon={<Download />}>
						Download
					</Button>

				</div>

			</div>
			<QuestionSetTable filterActive={filterActive}/>
			<UpsertQuestionSetModal
				key={upsertQuestionSetOpenCount}
				mode={upsertQuestionSetMode}
				setOpen={setUpsertQuestionSetOpen}
				open={upsertQuestionSetOpen}
				initialValues={upsertQuestionSetInitialState}
			/>

		</div>
	)

}
export default ReleaseNotes